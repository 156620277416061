@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

.font-work{
    font-family: 'Work Sans', sans-serif;
}
.h1{
    font-family: 'Work Sans', sans-serif;
    font-weight: bold;
    font-size: 64px;
    letter-spacing: -2%;
}
.h2{
    font-family: 'Work Sans', sans-serif;
    font-weight: bold;
    size: 40px;
    letter-spacing: -2%;
}
.h3{
    font-family: 'Work Sans', sans-serif;
    font-weight: bold;
    font-size: 24px;
    letter-spacing: -2%;
}
.subtitle{
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-size: 24px;
    letter-spacing: -2%;
}
body{
    font-family: 'Work Sans', sans-serif;

}

.reguler{
    font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 140%;
}
.small{
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-size: 14px;
}
.pretitle{
    font-family: 'Work Sans', sans-serif;
    font-weight: 700;
    font-size: 10px;
    letter-spacing: 3%;
}
.button{
    font-family: 'Work Sans', sans-serif;
    font-weight: 700;

}
.button-work{
    font-family: 'Work Sans', sans-serif;
    font-style: normal;
    font-weight: 700;

    /* identical to box height */
    
    text-align: center;
    text-transform: uppercase;
}
.link-16{
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 160%;
    /* identical to box height, or 26px */

    letter-spacing: 0.005em;
}
.link-14{
    font-family: 'Work Sans', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 140%;
}
.table {
    border-spacing: 0 4px;
}

.table tr {
    border-radius: 8px;
}

tr td:nth-child(n+5),
tr th:nth-child(n+5) {
    border-radius: 0 .625rem .625rem 0;
}

tr td:nth-child(1),
tr th:nth-child(1) {
    border-radius: .625rem 0 0 .625rem;
}
.hide-scroll-bar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .hide-scroll-bar::-webkit-scrollbar {
    display: none;
  }
  .show-scroll-bar{
    overflow-x: scroll;
  }