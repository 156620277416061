.react-google-flight-datepicker {
	font-size: 1rem
}

.react-google-flight-datepicker>* {
	box-sizing: border-box
}

.react-google-flight-datepicker .date-picker-demo {
	width: 400px
}

.react-google-flight-datepicker .btn-outline {
	background-color: transparent;
	background-repeat: no-repeat;
	border: none;
	cursor: pointer;
	overflow: hidden
}

.react-google-flight-datepicker .date-picker {
	position: relative;
	border-radius: 4px;
	background: white;
	padding: 6px
}

.react-google-flight-datepicker .date-picker.disabled {
	opacity: 0.6;
	cursor: default
}

.react-google-flight-datepicker .date-picker-input {
	width: 100%;
	display: flex;
	justify-content: space-between;
	border: 1px solid #dadce0;
	border-radius: 4px;
	color: #3c4043;
	height: 56px;
	letter-spacing: 0.2px;
	font-size: 1rem;
	align-items: center;
	overflow: visible
}

.react-google-flight-datepicker .date-picker-input svg {
	min-width: 24px;
	min-height: 24px
}

.react-google-flight-datepicker .date-picker-date-group {
	position: relative;
	display: flex;
	justify-content: space-between;
	height: 100%;
	flex-grow: 1
}

.react-google-flight-datepicker .date {
	display: flex;
	align-items: center;
	height: 100%;
	position: relative;
	border-radius: 2px 0 0 2px;
	padding: 0 13px;
	flex-grow: 1;
	width: 50%;
	background: white;
	border-radius: 4px
}

.react-google-flight-datepicker .date.is-single::before {
	display: none
}

.react-google-flight-datepicker .date:first-child::before {
	content: '';
	position: absolute;
	right: 0;
	top: 50%;
	transform: translate(0, -50%);
	width: 1px;
	height: 70%;
	background: #dadce0
}

.react-google-flight-datepicker .selected-date {
	display: flex;
	flex: 1 1 0px;
	align-items: center;
	height: 100%;
	font-size: 0.9rem;
	line-height: normal;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap
}

.react-google-flight-datepicker .date-placeholder {
	color: rgba(0, 0, 0, 0.56)
}

.react-google-flight-datepicker .change-date-group {
	width: 48px;
	display: flex
}

.react-google-flight-datepicker .change-date-group .change-date-button {
	height: 40px;
	color: #5f6368;
	cursor: pointer;
	padding: 8px 0;
	flex-grow: 1
}

.react-google-flight-datepicker .change-date-group .change-date-button:disabled {
	cursor: default
}

.react-google-flight-datepicker .change-date-group .change-date-button:disabled .icon-arrow {
	fill: #93989e
}

.react-google-flight-datepicker .icon-calendar {
	width: 24px;
	height: 24px;
	fill: #1a73e8;
	margin-right: 12px
}

.react-google-flight-datepicker .icon-calendar.mobile {
	display: none
}

.react-google-flight-datepicker .icon-arrow {
	width: 24px;
	height: 24px;
	fill: #5f6368
}

@keyframes showPopup {
	0% {
		transform: scale(0.7);
		opacity: 0
	}

	100% {
		transform: scale(1);
		opacity: 1
	}
}

@keyframes hidePopup {
	0% {
		transform: scale(1);
		opacity: 1
	}

	100% {
		transform: scale(0.7);
		opacity: 0;
		visibility: hidden
	}
}

.react-google-flight-datepicker .dialog-date-picker {
	background: #fff;
	border-radius: 5px;
	box-shadow: 0 1px 3px rgba(60, 64, 67, 0.3), 0 4px 8px 3px rgba(60, 64, 67, 0.15);
	min-width: 200px;
	position: absolute;
	top: -2px;
	
    right: 0;
	z-index: 120;
	height: max-content;
	width: 770px;
	margin-bottom: 50px;
	transform-origin: top left;
	opacity: 0;
	transform: scale(0)
}

.react-google-flight-datepicker .dialog-date-picker.single {
	width: 385px
}

.react-google-flight-datepicker .dialog-date-picker.open {
	animation: showPopup 0.2s forwards
}

.react-google-flight-datepicker .dialog-date-picker.hide {
	animation: hidePopup 0.2s forwards
}

.react-google-flight-datepicker .dialog-header {
	padding: 8px 8px 8px 24px;
	display: flex;
	justify-content: space-between;
	align-items: center
}

.react-google-flight-datepicker .dialog-header .date-picker-input {
	height: 48px;
	width: 50%
}

.react-google-flight-datepicker .date:focus,
.react-google-flight-datepicker .date.is-focus {
	background-color: #f4f8ff
}

.react-google-flight-datepicker .date:focus::after,
.react-google-flight-datepicker .date.is-focus::after {
	content: '';
	margin: -1px;
	border: 2px solid #1a73e8;
	border-radius: 4px;
	position: absolute;
	pointer-events: none;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 5
}

.react-google-flight-datepicker .back-button {
	display: none
}

.react-google-flight-datepicker .dialog-content {
	border-bottom: 1px solid #dadce0;
	border-top: 1px solid #dadce0;
	position: relative
}

.react-google-flight-datepicker .reset-button {
	font-size: 0.9rem;
	padding: 0 8px;
	height: 36px;
	border-radius: 4px;
	border: none;
	color: #5f6368;
	min-width: 64px
}

.react-google-flight-datepicker .reset-button:hover {
	background-color: #fafafa
}

.react-google-flight-datepicker .reset-button.mobile {
	display: none
}

.react-google-flight-datepicker .calendar-wrapper {
	margin: 12px 38px 8px;
	overflow: hidden;
	min-height: 353px
}

.react-google-flight-datepicker .calendar-wrapper.single {
	margin-left: 36px;
	margin-right: 36px
}

.react-google-flight-datepicker .calendar-content {
	display: flex;
	padding: 0 2px;
	position: relative;
	overflow: hidden
}

.react-google-flight-datepicker .calendar-content.isAnimating {
	transition: transform 0.2s ease;
	overflow: initial
}

.react-google-flight-datepicker .month-calendar {
	width: 308px;
	color: #3c4043;
	margin-right: 43px
}

.react-google-flight-datepicker .month-calendar.hidden {
	position: absolute;
	visibility: hidden;
	z-index: -1;
	opacity: 0
}

.react-google-flight-datepicker .month-calendar.isAnimating {
	position: absolute;
	left: -350px
}

.react-google-flight-datepicker .month-calendar.single {
	margin-right: 39px
}

.react-google-flight-datepicker .month-name {
	text-align: center;
	font-size: 1rem;
	margin-bottom: 10px
}

.react-google-flight-datepicker .weekdays {
	width: 100%;
	font-size: 0.9rem;
	color: rgba(0, 0, 0, 0.54);
	display: flex
}

.react-google-flight-datepicker .weekdays .weekday {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 36px;
	margin: 0;
	flex-grow: 1
}

.react-google-flight-datepicker .week {
	display: flex;
	justify-content: flex-start
}

.react-google-flight-datepicker .week.first {
	justify-content: flex-end
}

.react-google-flight-datepicker .day {
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	height: 44px;
	margin: 2px 0;
	text-align: center;
	width: 44px;
	vertical-align: top;
	position: relative;
	border-radius: 50%
}

.react-google-flight-datepicker .day .background-day {
	bottom: 0;
	opacity: 0;
	pointer-events: none;
	position: absolute;
	top: 0;
	width: 10px;
	z-index: 1
}

.react-google-flight-datepicker .day .background-day.first-day {
	background: linear-gradient(to right, #fff, #daedfd);
	left: -10px
}

.react-google-flight-datepicker .day .background-day.last-day {
	background: linear-gradient(to left, #fff, #daedfd);
	right: -10px
}

.react-google-flight-datepicker .day::after {
	border-radius: 100%;
	bottom: 0;
	content: '';
	height: 44px;
	left: 0;
	margin: auto;
	position: absolute;
	right: 0;
	top: 0;
	width: 44px;
	z-index: 2
}

.react-google-flight-datepicker .day:hover::after {
	background-color: #fff;
	border: 2px solid #6B0069;
	bottom: -2px;
	left: -2px;
	right: -2px;
	top: -2px
}

.react-google-flight-datepicker .day.selected {
	color: white
}

.react-google-flight-datepicker .day.selected:hover {
	color: #000
}

.react-google-flight-datepicker .day.selected::after {
	background-color: #6B0069;
	border-radius: 100%
}

.react-google-flight-datepicker .day.selected:hover::after {
	background-color: #fff
}

.react-google-flight-datepicker .day.highlight::before {
	content: '';
	width: 44px;
	height: 44px;
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background-color: #f7ed7ad1;
	border-radius: 50%
}

.react-google-flight-datepicker .day.hovered {
	background-color: #FCF5FC;
	border-radius: unset
}

.react-google-flight-datepicker .day.hovered .background-day {
	opacity: 1
}

.react-google-flight-datepicker .day.hovered.end {
	background: linear-gradient(90deg, #FCF5FC 50%, #fff 50%)
}

.react-google-flight-datepicker .day.hovered:hover {
	background-color: #FCF5FC
}

.react-google-flight-datepicker .day.hovered:hover::after {
	border-radius: 100%;
	background-color: #fff
}

.react-google-flight-datepicker .day.selected.hovered {
	background: linear-gradient(90deg, #fff 50%, #FCF5FC 50%);
	color: #fff
}

.react-google-flight-datepicker .day.selected.hovered.end {
	background: linear-gradient(90deg, #FCF5FC 50%, #fff 50%)
}

.react-google-flight-datepicker .day.selected.hovered:hover {
	color: #000
}

.react-google-flight-datepicker .day.selected.hovered::after {
	border-radius: 100%;
	background-color: #6B0069
}

.react-google-flight-datepicker .day.selected.hovered:hover::after {
	background-color: #fff
}

.react-google-flight-datepicker .day.disabled {
	pointer-events: none;
	color: rgba(0, 0, 0, 0.26)
}

.react-google-flight-datepicker .day.disabled.selected {
	color: #fff
}

.react-google-flight-datepicker .day .text-day {
	position: relative;
	z-index: 3
}

.react-google-flight-datepicker .calendar-flippers {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
	pointer-events: none
}

.react-google-flight-datepicker .flipper-button {
	background-color: #fff;
	border-radius: 100%;
	box-shadow: 0 0 4px rgba(0, 0, 0, 0.12), 0 4px 4px rgba(0, 0, 0, 0.24);
	cursor: pointer;
	height: 40px;
	width: 40px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 -20px;
	pointer-events: all
}

.react-google-flight-datepicker .flipper-button svg {
	width: 24px;
	height: 24px
}

.react-google-flight-datepicker .flipper-button.disabled {
	cursor: default
}

.react-google-flight-datepicker .flipper-button.disabled svg {
	fill: #93989e
}

.react-google-flight-datepicker .dialog-footer {
	align-items: center;
	display: flex;
	flex-direction: row-reverse;
	padding: 8px
}

.react-google-flight-datepicker .dialog-footer .submit-button {
	border-radius: 8px;
	padding: 10px 8px;
	border: none;
	line-height: 16px;
	text-transform: uppercase;
	color: #fff;
	background: #6B0069;
	height: 36px;
	min-width: 88px;
	font-weight: 500;
	font-size: 0.9rem;
	cursor: pointer
}

@media screen and (max-width: 768px) {
	.react-google-flight-datepicker .date-picker-demo {
		width: 100%
	}

	.react-google-flight-datepicker .date-picker-input {
		height: 40px;
		border: none;
		border-radius: 0;
		position: relative
	}

	.react-google-flight-datepicker .date {
		border: 1px solid #dadce0;
		border-radius: 5px;
		padding: 0 10px;
		flex-grow: 1
	}

	.react-google-flight-datepicker .date:nth-child(2) {
		margin-left: 10px
	}

	.react-google-flight-datepicker .date.is-focus {
		height: 40px
	}

	.react-google-flight-datepicker .date:first-child::before {
		display: none
	}

	.react-google-flight-datepicker .icon-calendar {
		display: none
	}

	.react-google-flight-datepicker .icon-calendar.mobile {
		display: block
	}

	.react-google-flight-datepicker .reset-button {
		display: none
	}

	.react-google-flight-datepicker .reset-button.mobile {
		display: block;
		color: rgba(0, 0, 0, 0.87);
		min-width: 88px;
		font-weight: 500
	}

	.react-google-flight-datepicker .change-date-group,
	.react-google-flight-datepicker .divider,
	.react-google-flight-datepicker .calendar-flippers,
	.react-google-flight-datepicker .weekdays {
		display: none
	}

	.react-google-flight-datepicker .back-button {
		display: block;
		width: 56px;
		height: 100%;
		margin-right: 16px;
		padding: 0
	}

	.react-google-flight-datepicker .back-button svg {
		width: 20px;
		height: 20px;
		fill: rgba(0, 0, 0, 0.7)
	}

	.react-google-flight-datepicker .dialog-date-picker {
		border-radius: 0;
		width: 100%;
		height: 100%;
		max-height: 100%;
		position: fixed;
		left: 0;
		right: 0;
		bottom: 10px;
		top: 100%;
		display: flex;
		flex-direction: column;
		opacity: 1;
		transform: none;
		z-index: 999999999
	}

	.react-google-flight-datepicker .dialog-date-picker.open {
		transition: all 0.2s ease-out;
		transform: translate(0, -100%);
		animation: none
	}

	.react-google-flight-datepicker .dialog-date-picker.hide {
		transition: all 0.2s ease-in;
		transform: translate(0, 0);
		animation: none
	}

	.react-google-flight-datepicker .dialog-header {
		padding: 8px;
		box-shadow: 0 0 2px rgba(0, 0, 0, 0.12), 0 2px 2px rgba(0, 0, 0, 0.24);
		z-index: 50
	}

	.react-google-flight-datepicker .dialog-header .date-picker-input {
		height: 40px;
		width: 100%
	}

	.react-google-flight-datepicker .dialog-content {
		border: none;
		overflow: hidden;
		height: 100%
	}

	.react-google-flight-datepicker .calendar-wrapper {
		margin: 0;
		height: 100%;
		display: flex
	}

	.react-google-flight-datepicker .calendar-content {
		padding: 0;
		width: 100%;
		height: 100%;
		flex-direction: column;
		flex-grow: 1
	}

	.react-google-flight-datepicker .weekdays.mobile {
		display: flex;
		background-color: #f3f3f3
	}

	.react-google-flight-datepicker .month-calendar {
		width: 100%;
		display: flex;
		flex-direction: column
	}

	.react-google-flight-datepicker .day {
		width: 14.28571%;
		max-width: 14.28571%
	}

	.react-google-flight-datepicker .day.selected:hover,
	.react-google-flight-datepicker .day.hovered:hover {
		color: #fff !important
	}

	.react-google-flight-datepicker .day.selected:hover::after,
	.react-google-flight-datepicker .day.hovered:hover::after {
		background-color: #6B0069 !important
	}

	.react-google-flight-datepicker .month-name {
		text-align: left;
		margin-left: 10px;
		margin-bottom: 0px;
		margin-top: 25px;
		font-weight: bold
	}

	.react-google-flight-datepicker .dialog-footer {
		box-shadow: 0 0 4px rgba(0, 0, 0, 0.12), 0 4px 4px rgba(0, 0, 0, 0.24);
		padding: 15px 8px
	}
}